import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Lightbox from "../components/lightbox"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      galleryImages: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      galleryImagesThumbs: allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Projects" />
      <Helmet
        bodyAttributes={{
          class: "page-projects",
        }}
      />
      <Row>
        <Col sm={12}>
          <h1>Projects</h1>
        </Col>
        <Col md={12}>
          <Lightbox
            galleryImages={data.galleryImages.edges}
            galleryImagesThumbs={data.galleryImagesThumbs.edges}
          />
        </Col>
      </Row>
    </Layout>
  )
}

export default Gallery
