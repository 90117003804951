import React, { Component } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

export default class Lightbox extends Component {
  static propTypes = {
    galleryImages: PropTypes.array.isRequired,
    galleryImagesThumbs: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      showLightbox: false,
      selectedImage: null,
    }
  }

  static state = {
    open: false,
  }

  nextImage(images, index, direction) {
    var newIndex
    if (index === 0 && direction === false) {
      newIndex = images.length - 1
    } else if (index === images.length - 1 && direction === true) {
      newIndex = 0
    } else {
      if (direction) {
        newIndex = index + 1
      } else {
        newIndex = index - 1
      }
    }
    return newIndex
  }

  render() {
    const { galleryImages } = this.props
    const { galleryImagesThumbs } = this.props
    const { selectedImage, showLightbox } = this.state
    return (
      <>
        <div className="lightbox-container">
          {galleryImagesThumbs.map((image, index) => (
            <button
              className="preview-button"
              key={image.node.childImageSharp.fluid.src}
              type="button"
              onClick={() =>
                this.setState({ showLightbox: true, selectedImage: index })
              }
            >
              <Img fluid={image.node.childImageSharp.fluid} />
            </button>
          ))}
        </div>
        {showLightbox && (
          <Dialog>
            <button
              type="button"
              className="lightbox-close"
              onClick={() => this.setState({ showLightbox: false })}
            >
              &times;
            </button>
            <Img
              fluid={galleryImages[selectedImage].node.childImageSharp.fluid}
            />
            <button
              className="lightbox-prev"
              onClick={() =>
                this.setState({
                  selectedImage: this.nextImage(
                    galleryImages,
                    selectedImage,
                    false
                  ),
                })
              }
            >
              &lt;
            </button>
            <button
              className="lightbox-next"
              onClick={() =>
                this.setState({
                  selectedImage: this.nextImage(
                    galleryImages,
                    selectedImage,
                    true
                  ),
                })
              }
            >
              &gt;
            </button>
          </Dialog>
        )}
      </>
    )
  }
}
